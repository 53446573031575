import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import about1 from '../../resources/themeContent/images/theme-images/about1.jpg';
import about2 from '../../resources/themeContent/images/theme-images/about2.jpg';
import signature from '../../resources/themeContent/images/theme-images/signature.png';


const Terms = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

  return (
    <>


      <Helmet>
        <title>{siteTitle} ASUREMART PTE LTD - Terms and Conditions</title>
        <meta name="description" content={siteTitle + " - Terms and Conditions page"}  />
        <meta name="keywords" content="Terms and Conditions"></meta>
      </Helmet>

      <SiteBreadcrumb title="Terms and Conditions" />

      <section className="about-area ptb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <p><b>Acceptance of Terms:</b><br/>
By accessing or using our e-commerce website ("Site"), you agree to comply with and be bound by these terms and conditions.

</p>
<p>To place an order, you must provide accurate and complete information. Prices are subject to change without notice. Full Payment is required before the order is processed. We will make reasonable efforts to deliver products within the specified timeframe. Shipping costs and delivery times may vary based on location and product availability.<br></br>
A lot of Indian manufacturers have a lot of strength and efficiency in production. But they are reluctant to control the cost of production and to advertise, sell, or distribute manufactured goods. 
</p>
<p>
To place an order, you must provide accurate and complete information. Prices are subject to change without notice. Full Payment is required before the order is processed. We will make reasonable efforts to deliver products within the specified timeframe. Shipping costs and delivery times may vary based on location and product availability.
</p>
<p>
<b>Benefit to Buyer Use Asuremart web site </b><br></br>
Asuremart is the only website in India that sells only Indian manufactured products On the Asuremart website, Buyer can buy the hardware items Buyer need through buyer mobile phone. No need to wander around looking for the hardware items you need. You can buy it through the Asuremart website. If buyer want to buy a product, Buyer can check the quality and price of the product. It is only possible through the Asuremart website. When buyer need to buy an item, Buyer can see many companies that can produce the same product through the Asuremart website. Through it buyer can see the different prices, and quality of products that many companies can produce. buyer can buy the highest quality products at the lowest prices through the Asuremart website. Even items you have never seen or heard of can be found on the Asuremart website. Directly in your hand from the manufacturer. No need to wander around looking for hardware items anymore. Asuremart for you. 
</p>
<p>If any person calls you or email/text you and asking for your personal details and claiming that they are from our company don’t provide any details. Beaware of scam and notify us about any issues. If you provide any personal details without our concern we are not responsible for that. After sales, you have to contact the product manufacturer company for any issues in your product. If they are not guiding you in a proper way, please notify us and we will take necessary action. Don’t disrespect any delivery person and if you have any issues, please contact our hotline support. Don’t spread any fake news about our company in public/social media, please do complaint your problem to us by contact our hotline support and we will take action against that. 
</p>
   <p>During transporting, if any government authority checks the product packages, then it will be in opened package, so we are not responsible for that. For Some product will start the manufacturing process only after order receiving, so there will be a time difference in delivery of your product. As per government regulations, GST will be collected from customer as per policies. Dangerous products and explosives, chemicals are not allowed to purchase by under 18 aged people. Before take any legal action, give a written statement to us and we will provide a solution within mentioned timeframe, if not satisfied then you may proceed. Don’t share your coupon code to others. Please provide proper delivery address and contact number. If any of the information provided by you is wrong, you are responsible for that. For any wrong transaction, please share screenshot with proper details and explanation.</p>         </div>
<p>Our products come with a limited warranty. Please review the warranty terms and conditions provided with the product. The warranty does not cover damage caused by misuse, neglect, or unauthorized modifications. You are responsible for maintaining the confidentiality of your account and password. Notify us immediately of any unauthorized use of your account.</p>
        <p>We shall not be liable for any direct, indirect, incidental, special, or consequential damages. Our total liability for any claim arising out of or related to our services shall not exceed the amount paid by you.</p>
        <p>Governing Law:<br/>
These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction].
</p>
<p>Changes to Terms:<br/>
We reserve the right to modify these terms at any time. Continued use of the Site constitutes acceptance of the modified terms. By using our website, you agree to abide by these terms and conditions. If you have any questions, please contact our customer service
</p>
  
  <p>Cancellation Policy:<br/>

Once the amount is paid for a product by the customer, there is no cancellation process. So, please double check before ordering a product.
</p>
<p>Returns and Refunds:<br/>

There is No Refunds & Returns. So, Please check your product while receiving at your place. In case of any wrong transaction of higher amount takes place, it will be refunded within 7 working days.
</p>
  <p>Privacy Policy:<br/>

We respect your privacy. Please refer to our "Privacy Policy" for details on how we collect, use, and protect your personal information. We do not store/share any personal information to any third parties for your privacy concern.
</p>
          
          </div>
        </div>
      </section>



      <BestFacilities />

    </>
  );

}

export default Terms;
