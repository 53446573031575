import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MakeApiCallSynchronous, MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import { showErrorMsg, showSuccessMsg, validateAnyFormField } from '../../../helpers/ValidationHelper';
import { GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';

const SubscribeNewsLetter = () => {
    const [SubscriberEmail, setSubscriberEmail] = useState('');
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    const submitSubscribeForm = async (event) => {
        event.preventDefault();

        let isValid = false;
        let validationArray = [];

        //--validation for email
        isValid = validateAnyFormField('Email', SubscriberEmail, 'email', null, 200, true);
        if (isValid == false) {
            validationArray.push({
                isValid: isValid
            });
        }

        //--check if any field is not valid
        if (validationArray != null && validationArray.length > 0) {

            isValid = false;
            return false;
        } else {
            isValid = true;
        }

        if (isValid) {


            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }


            const param = {
                requestParameters: {
                    SubscriberEmail: SubscriberEmail
                },
            };


            //--make api call for data operation
            const response = await MakeApiCallAsync(Config.END_POINT_NAMES['INSERT_SUBSCRIBER'], null, param, headers, "POST", true);
            if (response != null && response.data != null) {
                let detail = JSON.parse(response.data.data);
                if (detail[0].ResponseMsg == "Saved Successfully") {
                    showSuccessMsg("You have successfully subscribed to news channel!");

                    //--Empty form
                    setSubscriberEmail("");


                } else {
                    showErrorMsg("An error occured. Please try again later!");
                }
            }
        }

    }

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["SubscribeNewsLetter"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [])



    return (
        <>
            <span></span>
        </>
    );

}


export default SubscribeNewsLetter;
